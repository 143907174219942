@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap);
/* FONTS */

@font-face {
  font-family: "circular";
  src: url(/static/media/circular-book.21a2c3d3.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "circular";
  src: url(/static/media/circular-medium.1dd319b8.woff) format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "circular";
  src: url(/static/media/circular-black.fb41e601.woff) format("woff");
  font-weight: 700;
}

/* GLOBAL */

body {
  margin: 0;
  font-family: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  line-height: 1;
}

a,
button,
select {
  cursor: cell;
}

a {
  color: black;
  text-decoration: none;
}

*::selection {
  background: #caa3ff;
  color: white;
}

.floating-tooltip {
  height: 120px;
  position: absolute;
  display: none;
  padding: 8px;
  font-size: 12px;
  color: #131722;
  background-color: rgba(255, 255, 255, 1);
  text-align: left;
  z-index: 1000;
  top: 12px;
  left: 12px;
  border-radius: 5px;
  border: 1px solid black;
}

/* 
 * ReactTypingEffect override in spec:
 * https://github.com/KuroShibaONE/kuro-dapp/issues/17
 */

.react-typing-effect-cursor {
  transition: none !important;
}

/* Spinner Animation */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

